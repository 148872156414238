import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    creator: {
      address: null
    },
    seller: {
      address: null
    },
    contract: {
      type: null,
      endDate: null,
      price: null,
      decimals: null,
      bidHistory: null,
      asaId: null
    },
    nft: {
      title: null,
      tokenId: null,
      ipfsUrl: null
    },
    params: {
      appId: null,
      callback: null,
      price: null
    },
    wallet: {
      name: 'Pera Algo Wallet',
      api: 'pera',
      transactionPromise: null
    },
    transaction: {
      promise: null,
      state: 'transaction in progress'
    },
    errors: null
  },
  getters: {},
  mutations: {
    setContext (state, context) {
      Object.assign(state, context)
    },
    setAuction (state, auction) {
      Object.assign(state.auction, auction)
    },
    setParams (state, params) {
      state.params.appId = params.appId
      state.params.callback = params.callback
      state.params.basePrice = params.basePrice
    },
    setWallet (state, wallet) {
      state.wallet.name = wallet.name
      state.wallet.api = wallet.api
    },
    setTransactionState (state, transactionState) {
      state.transaction.state = transactionState
    },
    setTransactionPromise (state, transactionPromise) {
      state.transaction.promise = transactionPromise
    },
    setErrors (state, errors) {
      state.errors = errors
    },
    clearErrors (state) {
      state.errors = null
    }
  },
  actions: {},
  modules: {}
})
