<template>
  <div id="app">
    <div class="modal">
      <div class="container">
        <router-view />
      </div>
      <h5>powered by algogems</h5>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
const validate = require('validate.js')

export default {
  methods: {
    ...mapMutations(['setParams'])
  },
  mounted () {
    const url = new URL(window.location.href)
    const params = {
      appId: url.searchParams.get('id'),
      callback: url.searchParams.get('cb'),
      price: url.searchParams.get('p')
    }

    const constraints = {
      appId: {
        presence: {
          message: 'is required.'
        },
        format: {
          pattern: '[0-9]+',
          message: 'appId is invalid.'
        }
      },
      callback: {
        presence: {
          message: 'url is required.'
        },
        url: {
          schemes: ['.+']
        }
      },
      price: {
        numericality: {
          greaterThan: 0
        }
      }
    }

    const invalid = validate(params, constraints)
    if (invalid) {
      this.$error({ validationError: invalid })
    } else {
      this.setParams(params)
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,700&family=Varela+Round&display=swap');
body {
  margin: 0;
}

#app {
  --background: #F9F9F9;
  --foreground: #fff;
  --text: #2C3E50;
  --text-ligth: #B2B2B2;
  --border: #EFEFEF;
  --gradient: linear-gradient(45deg, #008EF7, #4D02B1);
  --shadow: 0px 10px 20px #ededed;
  --error: #E11D48;
}

#app {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background);
  font-family: 'Roboto', sans-serif;
}

.modal {
  animation: fade .5s ease-out .2s both;
}

.modal .container{
  width: 450px;
  height: 650px;
  box-sizing: border-box;
  background: var(--foreground);
  box-shadow: var(--shadow);
  border-radius: 20px;
}

.modal > h5{
  color: var(--text-ligth);
  text-align: center;
  font-weight: 500;
  font-size: 1em;
}

.flex {
  display: flex;
}

.button-secondary {
  background: none;
  color: var(--text-ligth);
  border: 2px solid var(--border);
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: var(--text-ligth);
}

.button-primary {
  background: var(--gradient);
  border: none;
  color: var(--foreground);
  width: 100%;
  border-radius: 20px;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 1em;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-in {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>
