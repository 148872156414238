import MyAlgoAPI from './Wallets/MyAlgo'
import { AlgoWalletAPI } from './Wallets/AlgoWallet'
import axios from 'axios'

export function getWalletAPI (wallet) {
  if (wallet.api === 'myalgo') {
    return MyAlgoAPI
  } else {
    return AlgoWalletAPI
  }
}

export function getParams () {
  return new Promise((resolve, reject) => {
    axios.get('/api/sdk/param').then(({ data }) => {
      if (data) {
        resolve({
          firstRound: data.last_round,
          lastRound: data.last_round + 1000,
          genesisId: data.genesis_id,
          genesisHash: data.genesis_hash,
          fee: data.fee
        })
      }
    }).catch(err => {
      reject(err)
    })
  })
}

export function _base64ToArrayBuffer (base64) {
  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes
}

export function longToByteArray (long) {
  const byteArray = [0, 0, 0, 0, 0, 0, 0, 0]

  for (let index = byteArray.length - 1; index >= 0; index--) {
    const byte = long & 0xff
    byteArray[index] = byte
    long = (long - byte) / 256
  }

  return new Uint8Array(byteArray)
}
