<template>
  <div id="transaction-review" v-if="nft.title">
    <div class="header">
      <h1>Review transaction</h1>
      <button class="button-secondary" @click="cancel"><unicon name="times"/>Cancel</button>
    </div>
    <div class="transaction-amount">
      <h2>Your bid:</h2>
      <div class="flex">
        <img src="../assets/algorand-algo-logo.svg" alt="algo logo"/>
        <div class="input-wrapper">
          <input type="number" v-model="price" :min="nft.price"/>
        </div>
      </div>
    </div>
    <div class="nft-preview">
      <h2>Auction:</h2>
      <div class="flex">
        <img :src="nft.ipfsUrl" :alt="nft.tokenId"/>
        <ul>
          <li>{{ nft.title }}</li>
          <li>Asa ID:{{ nft.tokenId }}</li>
          <li>by {{ creator.address | address }}</li>
        </ul>
        <a :href="nft.ipfsUrl" target="_blank"><unicon name="external-link-alt"/></a>
      </div>
    </div>
    <div class="seller">
      <h2>Sold by:</h2>
      <div class="flex">
        <JazzIcon class="jazzicon" :address="seller.address.hexEncode()" :diameter="50"/>
        <span>{{ seller.address | address }}</span>
      </div>
    </div>
    <div class="call-to-action">
      <template v-if="!transaction.promise">
        <button class="button-primary" @click="buy">pay with <b>{{ wallet.name }}</b></button>
        <!--<router-link :to="{name: 'select-wallet'}">Use another wallet</router-link>-->
      </template>
      <div class="loading" v-else>
        <LinearSpinner/>
        {{ transaction.state }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import LinearSpinner from '@/components/LinearSpinner'
import buy from '@/web3/contracts/buy'
import { getWalletAPI } from '@/web3/utils'

export default {
  name: 'PayView',
  components: { LinearSpinner },
  data () {
    return {
      price: 0
    }
  },
  filters: {
    address (val) {
      return `${val.slice(0, 10)}.${val.slice(-10)}`
    }
  },
  computed: {
    ...mapState(['params', 'wallet', 'creator', 'seller', 'contract', 'nft', 'transaction'])
  },
  methods: {
    ...mapMutations(['setContext', 'setTransactionPromise']),
    getAppData () {
      if (this.params.appId) {
        this.$http.get('/api/main/info', { params: { app_id: this.params.appId } }).then(({ data }) => {
          this.setContext({
            creator: {
              address: data.creator
            },
            seller: {
              address: data.seller
            },
            contract: {
              type: data.action,
              endDate: data.end_date,
              price: data.price / Math.pow(10, 6),
              decimals: data.decimal,
              bidHistory: data.bid_history,
              asaId: data.asa_id
            },
            nft: {
              title: data.title,
              tokenId: data.token_id,
              ipfsUrl: data.url
            }
          })
          if (this.contract.price < this.params.price) {
            this.price = this.params.price
          } else {
            this.price = this.contract.price
          }
        }).catch(err => {
          this.$error(err)
        })
      }
    },
    cancel () {
      document.location = document.referrer
    },
    buy () {
      const walletApi = getWalletAPI(this.wallet)
      walletApi.logout().then(() => {
        walletApi.connect().then(addresses => {
          const p = buy(this.wallet.api, addresses[0].address, parseInt(this.params.appId, 10))
          this.setTransactionPromise(p)
          this.transaction.promise.then(() => {
            document.location = this.params.callback
          }).catch(err => {
            console.log(err)
            if (err.response && err.response.data && err.response.data.detail) {
              this.$error(err.response.data.detail)
            } else {
              this.$error(err)
            }
          })
        })
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getAppData()
    })
  }
}
</script>

<style scoped>
h2 {
  font-size: .8em;
  color: var(--text-ligth);
  font-weight: 400;
  margin: 5px 7px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 45px;
}

.header h1{
  background: var(--gradient);
  background-clip: text;
  color: transparent;
  font-weight: 600;
  font-size: 1.5em;
  margin: 0;
  padding: 0;
}

.transaction-amount {
  padding: 25px 45px;
  border-top: 2px solid var(--border);
  border-bottom: 2px solid var(--border);
  animation-delay: .1s;
}

.transaction-amount .flex{
  align-items: center;
}

.transaction-amount img {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.transaction-amount .input-wrapper {
  flex: 1;
}

.transaction-amount input[type=number] {
  -moz-appearance: textfield;
  font-size: 2em;
  font-weight: 700;
  border: none;
  background: none;
  color: var(--text);
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}

.transaction-amount input[type=number]:focus {
  outline: none;
  background: var(--background);
}

.transaction-amount input::-webkit-outer-spin-button,
.transaction-amount input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.nft-preview {
  padding: 35px 45px 10px 45px;
  animation-delay: .2s;
}

.nft-preview .flex{
  align-items: center;
}

.nft-preview img {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-right: 15px;
}

.nft-preview ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: var(--text-ligth);
  font-size: .8em;
  flex: 1;
}

.nft-preview li {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nft-preview li:first-child{
  color: var(--text);
  font-size: 1.5em;
  font-weight: 600;
}

.nft-preview a{
  fill: var(--text);
}

.seller {
  padding: 10px 45px 35px 45px;
  animation-delay: .25s;
}

.seller .flex{
  align-items: center;
}

.seller .jazzicon{
  margin: 0 20px 0 5px;
}

.seller span{
  color: var(--text);
  font-size: 1.1em;
  font-weight: 600;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.call-to-action{
  border-top: 2px solid var(--border);
  padding: 35px 45px 45px 45px;
  animation-delay: .3s;
}

.call-to-action b{
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}

.call-to-action a{
  color: var(--text-ligth);
  font-size: .9em;
  text-align: center;
  display: block;
  margin-top: 5px;
}

.call-to-action .loading{
  text-align: center;
  color: var(--text-ligth);
  margin-top: 20px;
}

.header, .transaction-amount, .nft-preview, .seller, .call-to-action{
  animation-name: slide-in;
  animation-duration: .4s;
  animation-timing-function: ease;
  animation-fill-mode: both;
}
</style>
