const encoder = require('uint8-to-base64')
const algosdk = require('algosdk')

const MyAlgo = require('@randlabs/myalgo-connect')

const myAlgoWallet = new MyAlgo()

module.exports = class MyAlgoAPI {
  static name = 'myalgo'
  static connect (callback = () => {}, errorCallback = () => {}) {
    myAlgoWallet.connect()
      .then((d) => {
        callback(d)
      })
      .catch((e) => {
        errorCallback({ code: 'contract-error-address' })
      })
  }

  static logout (callback = () => { }, errorCallback = () => { }) {
    return new Promise((resolve, reject) => { resolve() })
  }

  static async account () {
    const d = await myAlgoWallet.connect()
    if (!d) {
      throw new Error({ code: 'contract-error-address' })
    }
    return d
  }

  static async sign (txn, getUint8 = false) {
    console.log('MyAlgo - Sign', txn)
    txn = txn.toByte()
    const d = await myAlgoWallet.signTransaction(txn)
    if (!d) {
      console.error(d)
      throw new Error('contract-sign-error')
    }
    console.log('MyAlgo - sign result', d)
    if (!getUint8) {
      d.blob = encoder.encode(d.blob)
    }
    return d
  }

  static async signGroup (txns, isSigning, isGrouped = true) {
    let toSign = []
    if (isGrouped) {
      const txngroup = algosdk.assignGroupID(txns)
      for (let i = 0; i < txns.length; i++) {
        txns[i].group = txngroup[i].group
        if (isSigning[i]) {
          toSign.push(txns[i].toByte())
        }
      }
      console.log('MyAlgo - sign group', toSign, isSigning)
    } else {
      toSign = txns.map(x => x.toByte())
    }

    const d = await myAlgoWallet.signTransaction(toSign)
    for (const stx of d) {
      stx.blob = encoder.encode(stx.blob)
    }
    console.log('MyAlgo - signGroup result', d)
    return d
  }
}
