<template>
  <div>
    <button @click="useAnotherPeraAddress">Disconnect</button>
  </div>
</template>

<script>
import { AlgoWalletAPI } from '@/web3/Wallets/AlgoWallet'

export default {
  name: 'WalletSelect',
  methods: {
    useAnotherPeraAddress () {
      AlgoWalletAPI.logout()
    }
  }
}
</script>

<style scoped>

</style>
