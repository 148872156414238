import algosdk from 'algosdk'
import axios from 'axios'
import { getWalletAPI, getParams } from '../utils'

export default async function buy (wallet, address, appId) {
  const nftCount = 1
  const response = await axios.get('/api/sale/buy', { params: { app_id: appId, address, number: nftCount } })
  if (response.data) {
    const metadata = response.data
    const params = await getParams()
    const appAddr = algosdk.getApplicationAddress(appId)
    // Building tx list
    const txns = []
    if (metadata.optin) {
      txns.push(algosdk.makeAssetTransferTxnWithSuggestedParams(metadata.from, metadata.from, undefined, undefined, 0, undefined, metadata.token_id, params))
    }
    if (metadata.asa_id === 1) {
      txns.push(algosdk.makePaymentTxnWithSuggestedParams(metadata.from, appAddr, nftCount * metadata.micro_amount, undefined, undefined, params))
    } else {
      txns.push(algosdk.makeAssetTransferTxnWithSuggestedParams(metadata.from, appAddr, undefined, undefined, nftCount * metadata.micro_amount, undefined, metadata.asa_id, params))
    }
    // Building contract args
    const appArgs = []
    const addresses = [metadata.seller, metadata.gems_fees, metadata.royalties, metadata.from]
    const foreignAssets = [metadata.token_id]
    appArgs.push(new TextEncoder().encode('buy'))
    if (metadata.asa_id !== 1) {
      foreignAssets.push(metadata.asa_id)
    }
    // Contract invocation tx
    txns.push(algosdk.makeApplicationNoOpTxn(metadata.from, params, metadata.app_id, appArgs, addresses, undefined, foreignAssets, undefined, undefined, undefined))
    // Signing tx list
    const walletApi = getWalletAPI(wallet)
    const signedTxs = await walletApi.signGroup(txns, txns.map(() => true))
    // Sending signed tx list to api
    return axios.post('/api/sale/buy', {
      signed_tx: signedTxs,
      app_id: metadata.app_id
    })
  }
}
