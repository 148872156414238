<template>
  <div class="linear-spinner">
    <div class="spinner"></div>
    <p><slot></slot></p>
  </div>
</template>

<script>
export default {
  name: 'LinearSpinner'
}
</script>

<style scoped>
.spinner {
  width: 200px;
  overflow: hidden;
  position: relative;
  height: 0.5rem;
  background: var(--border);
  border-radius: 20px;
  margin: 0 auto;
}

.spinner::before {
  content: "";
  position: absolute;
  left: -130%;
  width: 100%;
  height: 100%;
  background: var(--border);
  animation: progress 2s linear infinite;
}

@keyframes progress {
  0% {
    left: -130%;
    background: var(--gradient);
  }
  50% {
    left: 130%;
    background: var(--gradient);
  }
  51% {
    background: var(--gradient);
  }
  100% {
    background: var(--gradient);
  }
}
</style>
