import Vue from 'vue'
import VueRouter from 'vue-router'
import Pay from '@/views/Pay'
import WalletSelect from '@/views/WalletSelect'
import Error from '@/views/Error'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'pay',
    component: Pay
  },
  {
    path: '/select-wallet',
    name: 'select-wallet',
    component: WalletSelect
  },
  {
    path: '/error',
    name: 'error',
    component: Error
  }
]

const router = new VueRouter({
  mode: 'abstract',
  base: process.env.BASE_URL,
  routes
})

export default router
