<template>
<div id="error" v-if="errors">
  <ul v-if="errors.validationError">
    <template v-for="errorList in errors.validationError">
      <li v-for="error in errorList" :key="error">{{error}}</li>
    </template>
  </ul>
  <h4 v-else>{{ errors }}</h4>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ErrorView',
  computed: {
    ...mapState(['errors'])
  }
}
</script>

<style scoped>
#error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 650px;
  padding: 45px;
  box-sizing: border-box;
  color: var(--error);
}

#error ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 1.2em;
}
</style>
