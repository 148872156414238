import Vue from 'vue'
import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd'
import {
  uniTimes,
  uniExternalLinkAlt,
  uniTimesCircle
} from 'vue-unicons/dist/icons'

Unicon.add([
  uniTimes,
  uniExternalLinkAlt,
  uniTimesCircle
])

Vue.use(Unicon)
