import { render, staticRenderFns } from "./WalletSelect.vue?vue&type=template&id=483396b3&scoped=true&"
import script from "./WalletSelect.vue?vue&type=script&lang=js&"
export * from "./WalletSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "483396b3",
  null
  
)

export default component.exports