import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Jazzicon from 'vue-jazzicon'

Vue.use(VueAxios, axios)
if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://api.pay.algogems.io/'
}
Vue.component('JazzIcon', Jazzicon)

Vue.config.productionTip = false

require('./unicons')

Vue.mixin({
  methods: {
    $error (errors) {
      this.$store.commit('setErrors', errors)
      this.$router.push({ name: 'error' })
    }
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

router.replace('/')

// eslint-disable-next-line no-extend-native
String.prototype.hexEncode = function () {
  let hex, i
  let result = ''
  for (i = 0; i < this.length; i++) {
    hex = this.charCodeAt(i).toString(16)
    result += ('000' + hex).slice(-4)
  }

  return result
}
